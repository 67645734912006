import React, { useState } from "react";
import logo from "../images/logo.png";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const NavLink = ({ children }) => (
    <a className="text-slate-50 text-xl hover:text-white md:text-lg" href="/">
      {children}
    </a>
  );

  return (
    <nav className="relative top-0 inset-x-0 w-screen bg-slate-700">
      <div className="container flex justify-between items-center mx-auto py-4 px-8">
        {/* Logo */}
        <img className="h-20" src={logo} alt="Logo" />
        {/* Desktop nav items */}
        <div className="hidden space-x-4 items-center md:flex">
          <NavLink>Home</NavLink>
          <NavLink>Services</NavLink>
          <NavLink>About</NavLink>
          <NavLink>Contact</NavLink>

          <button
            style={{
              display: process.env.NODE_ENV !== "development" ? "none" : "",
              color: "white",
            }}
            onClick={() => {
              window.open("https://convenientcarecenter.com/unhide");
            }}
          >
            LIVE
          </button>
        </div>

        {/* Hamburger button */}
        <button
          id="hamburger"
          className={`${
            menuOpen ? "open" : ""
          } flex flex-col space-y-1.5 md:hidden`}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span className="w-8 h-0.5 bg-slate-50" />
          <span className="w-8 h-0.5 bg-slate-50" />
          <span className="w-8 h-0.5 bg-slate-50" />
        </button>
      </div>

      {/* Mobile menu */}
      <div
        className={`transition-all ease-out duration-300 absolute inset-x-0 top-full overflow-hidden bg-slate-500 md:hidden`}
        style={{ maxHeight: menuOpen ? "200%" : "0%" }}
      >
        <div
          className={`container flex flex-col items-center space-y-4 mx-auto py-4`}
        >
          <NavLink>Home</NavLink>
          <NavLink>Services</NavLink>
          <NavLink>About</NavLink>
          <NavLink>Contact</NavLink>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
