import React from "react";

function CarouselSlide({children, bg}) {
    return (
        <div className='w-full bg-no-repeat bg-cover bg-center' style={{ backgroundImage: `url('${bg}')` }}>
            <div className='h-[61vh] py-10 bg-black/40'>
                <div className='section-container text-white'>
                    {children}
                </div>
            </div>
        </div>
    );
}
export default CarouselSlide;
