import React from "react";
import { GrMap } from "react-icons/gr";
import { FiPhoneCall } from "react-icons/fi";
import LoadOfficeMap from "./LoadOfficeMap";
//import OpenMapHold from "./OpenMapHold";

const Footer = () => {
  const footerListCss =
    "m-7 text-2xl text-center min-h-[3rem] text-slate-900 bg-white hover:bg-stone-600 hover:text-white";

  return (
    <section id="footer" class="h-full bg-slate-900 flex flex-col lg:flex-row">
      <div class="flex-1 flex flex-col justify-center items-center md:flex ml-2">
        <h1
          className="text-slate-100"
          style={{ fontFamily: "Open Sans", fontSize: "50px" }}
        >
          Come Visit Us!
        </h1>
        <LoadOfficeMap />
        <div className="flex mt-3">
          <a
            href="https://goo.gl/maps/k6j2d2WhNGz9rYje6"
            target="_blank"
            rel="noreferrer"
          >
            <button className="bg-cyan-700 flex  text-slate-100 m-3 p-2 rounded-lg">
              <GrMap className="mr-2 h-7" /> Navigate to Us
            </button>
          </a>{" "}
          <a href="tel:+18625911195">
            <button className="bg-green-600 flex  text-slate-100 m-3 p-2 rounded-lg">
              <FiPhoneCall className="mr-2 h-7" /> Give Us A Call
            </button>
          </a>{" "}
        </div>
      </div>
      <div class="flex-1 flex-col items-center mt-5 justify-center md:flex-row">
        <ul className="text-green-100 list-none grid grid-flow-row-dense grid-cols-1 grid-rows-5 text-center list-inside md:mt-3 lg:grid-cols-2">
          <li className={footerListCss}>Home</li>
          <li className={footerListCss}>About</li>
          <li className={footerListCss}>Services</li>
          <li className={footerListCss}>Blog</li>
          <li className={footerListCss}>Contact</li>
          <li className={footerListCss}>Home</li>
          <li className={footerListCss}>About</li>
          <li className={footerListCss}>Services</li>
          <li className={footerListCss}>Blog</li>
          <li className={footerListCss}>Contact</li>
        </ul>
      </div>
    </section>
  );
};

export default Footer;
