import React from "react";

import UnitedHealthcare from "../../../src/images/insurance/unitedhealthcare.png";
import BluecrossBlueSheild from "../../../src/images/insurance/bluecross-bluesheild.png";
import Optumrx from "../../../src/images/insurance/optumrx.png";
import Amerigroup from "../../../src/images/insurance/amerigroup.png";
import Clover_Health from "../../../src/images/insurance/clover_health.png";
import ExpressScripts from "../../../src/images/insurance/express_scripts.png";

const Insurance = () => {
  const listCSS = "m-7 text-center rounded-md ";

  return (
    <>
      <div className="mt-52">
        <div className="text-center text-6xl font-semibold">
          Your Insurance Trusts Us
        </div>
        <div class="flex-1 flex-col items-center mt-5 justify-center md:flex-row">
          <ul className="text-green-100 list-none grid grid-flow-row-dense grid-cols-1 grid-rows-2 text-center list-inside md:grid-cols-2 lg:grid-cols-3">
            <li className={listCSS}>
              <img src={UnitedHealthcare} alt="United Healthcare"></img>
            </li>
            <li className={listCSS}>
              <img src={BluecrossBlueSheild} alt="BlueCross BlueShield"></img>
            </li>
            <li className={listCSS}>
              <img src={Optumrx} alt="OptumRX"></img>
            </li>
            <li className={listCSS}>
              <img src={Amerigroup} alt="Amerigroup"></img>
            </li>
            <li className={listCSS}>
              <img src={Clover_Health} alt="Clover Health"></img>
            </li>
            <li className={listCSS}>
              <img src={ExpressScripts} alt="Express Scripts"></img>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Insurance;
