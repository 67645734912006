import React from 'react'
import portrait from "../../images/img-doc.jpg";


const MeetTheDoc = () => {
    return (
        <>      <section
            className={`section-container space-y-8 
                md:flex md:flex-row md:space-x-8 md:space-y-0`}
        >
            <div className="w-full md:w-[39vw] md:min-w-[10rem]">
                <img className="w-full" src={portrait} alt="Dr. Zibdie" />
            </div>
            <div>
                <h1 className="text-3xl pb-4">Meet Dr. Zibdie</h1>
                <p>
                    With over 20 years of experience from our staff combined, the
                    Convienant Care Center has never been the better place to get the
                    treatment and diagnostics for you and family's healthcare needs! We
                    strive to combine the advancements in medicine and technology with
                    the traditional family doctor approach of days gone by. Whether you
                    have a strong uncomofortable feeling or needing to treat injuries
                    such as slips and falls, cuts, bruises, we will be right there!
                </p>
            </div>
        </section></>
    )
}

export default MeetTheDoc