import React from "react";
import Home from "./pages/Home";
//import Construction from "./pages/misc/Construction";
import NotFound from "./pages/NotFound";

import AcuteIllness from "./pages/Services/AcuteIllness";
import HealthAndWellness from "./pages/Services/HealthAndWellness";
import IVInfusionTherapy from "./pages/Services/IVInfusionTherapy";
import PreventiveCare from "./pages/Services/PreventiveCare";

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  /*
    https://convenientcarecenter.com/
    */

  return (
    <BrowserRouter>
      <main>
        <Routes>
          <Route
            path="/"
            element={
                <Home />
            }
          />
          <Route path="/unhide" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          {/* Services Below */}
          <Route path="/services/acuteillness" element={<AcuteIllness />} />
          <Route
            path="/services/healthandwellness"
            element={<HealthAndWellness />}
          />
          <Route
            path="/services/ivinfusiontherapy"
            element={<IVInfusionTherapy />}
          />
          <Route path="/services/preventivecare" element={<PreventiveCare />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;
