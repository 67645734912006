import Navbar from "../../components/Navbar";
//import HeroSlide from "./HeroSlide";
import CarouselSlideHomePage from "./CarouselSlideHomePage";
import Footer from "./Footer";
import Insurance from "./Insurance";
import TabSection from "./TabSection";
import { Link } from "react-router-dom";
import { GiHospitalCross } from "react-icons/gi";
import MeetTheDoc from "./MeetTheDoc";

function Home() {
  const ServiceHighlightBox = ({ title, description, link, icon }) => {
    return (
      <>
        <div className="box-border bg-cyan-900 justify-center items-center h-48 w-48 border-4">
          <Link
            to={link ? link : ""}
            style={{ cursor: link ? "pointer" : "auto" }}
          >
            <div className="flex justify-center items-center">{icon}</div>
            <div className="text-center font-semibold text-red-800 ">
              {title}
            </div>
            <div className="text-center text-orange-400	">{description}</div>
          </Link>
        </div>
      </>
    );
  };

  return (
    <>
      <Navbar />
      {/* Top banner */}
      <CarouselSlideHomePage />
      <MeetTheDoc />
      <section className="grid grid-cols-2 gap-4 content-evenly mt-4 md:flex md:justify-around">
        <>
          <ServiceHighlightBox
            title="Acute Illness"
            description="We are here to help you with your health needs."
            link="/services/acuteillness"
            icon={<GiHospitalCross size={60} />}
          />
          <ServiceHighlightBox
            title="your health"
            description="We are here to help you with your health needs."
          />
          <ServiceHighlightBox
            title="your health"
            description="We are here to help you with your health needs."
          />
          <ServiceHighlightBox
            title="your health"
            description="We are here to help you with your health needs."
          />
        </>
      </section>
      <TabSection />
      <Insurance />
      {/* Footer */}
      <Footer />
    </>
  );
}

export default Home;
