import React from "react";

const LoadOfficeMap = () => {
  return (
    <>
      <div id="mainMap" className="google-map-code">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3016.7606205713932!2d-74.13827874858679!3d40.87712723533143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2feb8268e852d%3A0xb4ff9aab905f3f07!2s57%20Lakeview%20Ave%2C%20Clifton%2C%20NJ%2007011!5e0!3m2!1sen!2sus!4v1664848270574!5m2!1sen!2sus"
          title="gMapLocation"
          width="600"
          height="450"
          frameborder="0"
          className="rounded-xl"
          style={{ border: 0 }}
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </>
  );
};

export default LoadOfficeMap;
