import React from "react";
import Slider from "react-slick"; // Check out the API and examples here: https://react-slick.neostack.com/docs/get-started

import CarouselSlide from "../../components/CarouselSlide";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselSlideHomePage = () => {
  const infoSliderSettings = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 6000,
  };
  return (
    <section className="section-container-wide">
      <Slider {...infoSliderSettings}>
        <CarouselSlide bg={`${process.env.PUBLIC_URL}/assets/images/ofc/1.jpg`}>
          <h1 className="text-3xl">Meet Dr. Zibdie</h1>
          <br />
          <p>
            With over 20 years of experience from our staff combined, the
            Convienant Care Center has never been the better place to get the
            treatment and diagnostics for you and family's healthcare needs! We
            strive to combine the advancements in medicine and technology with
            the traditional family doctor approach of days gone by. Whether you
            have a strong uncomofortable feeling or needing to treat injuries
            such as slips and falls, cuts, bruises, we will be right there!
          </p>
        </CarouselSlide>
        <CarouselSlide bg={`${process.env.PUBLIC_URL}/assets/images/ofc/2.jpg`}>
          <h1 className="text-3xl">Always near your home</h1>
          <br />
          <p>
            The Convenient Care Center is located conveniantly right here in
            Clifton! We are 10 minutes away from the Paterson/Clifton exists on
            Route 46 and Route 80 so you can easily visit us from Passaic,
            Bergen, Essex, Morris, and Hudson counties.
          </p>
        </CarouselSlide>
        <CarouselSlide bg={`${process.env.PUBLIC_URL}/assets/images/ofc/3.jpg`}>
          <h1 className="text-3xl">
            A Family Doctor That's There For Your Family
          </h1>
          <br />
          <p>
            The Convienant Care Center has 20 years of experience in providing
            care to all patients. We are a family doctor that is there for your
            family.
          </p>
        </CarouselSlide>
        <CarouselSlide bg={`${process.env.PUBLIC_URL}/assets/images/ofc/4.jpg`}>
          <h1 className="text-3xl">
            We hired Vsauce as an intern. Here's what he said to one of our
            patients.
          </h1>
          <br />
          <p>
            Hey, Vsauce. Michael here. Where are your fingers? Seriously. It's a
            pretty easy question. You should be able to answer it. But how do
            you know? How does anyone know anything?
            <br />
            <br />
            You might say, well, I know where my fingers are. I'm looking right
            at them. Or, I can touch them, I can feel them, they're right here
            and that's good. Your senses are a great way to learn things. In
            fact, we have way more than the usual five senses we talk about. For
            instance, your kinesthetic sense, proprioception. This is what the
            police evaluate during a field sobriety test. It allows you to tell
            where your fingers and arms and head and legs in your body is all in
            relation to each other without having to look or touch other things.
            We have way more than five senses, we have at least twice as many
            and then some. But they're not perfect.
          </p>
        </CarouselSlide>
      </Slider>
    </section>
  );
};

export default CarouselSlideHomePage;
