import React, { useState } from 'react'
import "./TabSelection.css";
/*
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
*/

/* https://github.com/Ziratsu/yt-react-tabs */

const TabSection = () => {

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    const tabBody = (index) => {
        if (index === 1) {
            return (
                <>
                    <h2>Content 1</h2>
                    <hr />
                    <p>
                        AYYYYY
                    </p>
                </>
            )
        } else if (index === 2) {
            return (
                <>Hi</>
            )
        } else if (index === 3) {
            return (
                <>Hi</>
            )
        } else {
            return (
                <>Hi</>
            )
        }
    }


    return (
        <>
            <div className="tab-box-container">
                <div className="tab-bloc-tabs">
                    <button
                        className={toggleState === 1 ? "tab-tabs tab-active-tabs tab-button" : "tab-tabs tab-button"}
                        onClick={() => toggleTab(1)}
                    >
                        Tab 1
                    </button>
                    <button
                        className={toggleState === 2 ? "tab-tabs tab-active-tabs tab-button" : "tab-tabs tab-button"}
                        onClick={() => toggleTab(2)}
                    >
                        Tab 2
                    </button>
                    <button
                        className={toggleState === 3 ? "tab-tabs tab-active-tabs tab-button" : "tab-tabs tab-button"}
                        onClick={() => toggleTab(3)}
                    >
                        Tab 3
                    </button>
                </div>

                <div className="tab-content-tabs">
                    <div
                        className={toggleState === 1 ? "tab-content tab-active-content" : "tab-content"}
                    >
                        {tabBody(1)}
                    </div>

                    <div
                        className={toggleState === 2 ? "tab-content tab-active-content" : "tab-content"}
                    >
                        <h2>Content 2</h2>
                        <hr />
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
                            voluptatum qui adipisci.
                        </p>
                    </div>

                    <div
                        className={toggleState === 3 ? "tab-content tab-active-content" : "tab-content"}
                    >
                        <h2>Content 3</h2>
                        <hr />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos sed
                            nostrum rerum laudantium totam unde adipisci incidunt modi alias!
                            Accusamus in quia odit aspernatur provident et ad vel distinctio
                            recusandae totam quidem repudiandae omnis veritatis nostrum
                            laboriosam architecto optio rem, dignissimos voluptatum beatae
                            aperiam voluptatem atque. Beatae rerum dolores sunt.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TabSection